import { legacyVars } from './legacyVars.css';

// Typography bases
const saolBase = {
  fontFamily: legacyVars.typography.saol,
  lineHeight: '1.3em',
  letterSpacing: 0.5,
  fontWeight: '400',
};

const simplonMonoBase = {
  fontFamily: legacyVars.typography.simplonMono,
  lineHeight: '1.5em',
  letterSpacing: 1,
  textTransform: 'uppercase',
  fontWeight: '400',
};

const simplonNormBase = {
  fontFamily: legacyVars.typography.simplonNorm,
  lineHeight: '1.5em',
  letterSpacing: 0.5,
  fontWeight: '400',
};

// responsive
const breakpoints = {
  xs: 360,
  sm: 768, // tablet width (portrait)
  md: 1024, // tablet width (landscape)
  lg: 1280, // desktop width
  xl: 1920,
} as const;

const breakpointMediaDown = (value: number) => `@media (max-width: ${value - 0.05}px)`;
const breakpointMediaUp = (value: number) => `@media (min-width: ${value - 0.05}px)`;
const ipadProPortraitBreakpoints = `@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)`;

type LegacyBreakpointsKey = keyof typeof breakpoints;

const breakpointDown = (value: LegacyBreakpointsKey | number) => {
  if (typeof value === 'string') {
    return breakpointMediaDown(breakpoints[value]);
  }
  return breakpointMediaDown(value);
};

const breakpointUp = (value: LegacyBreakpointsKey | number) => {
  if (typeof value === 'string') {
    return breakpointMediaUp(breakpoints[value]);
  }
  return breakpointMediaUp(value);
};

export const typographyVariants = {
  hero1: {
    ...saolBase,
    lineHeight: '1.15em',
    fontSize: '70px',
    letterSpacing: 1,
    [breakpointDown('sm')]: {
      fontSize: '42px',
    },
  },
  hero2: {
    ...saolBase,
    lineHeight: '1.15em',
    fontSize: '48px',
    letterSpacing: 1,
  },
  'hero-mono': {
    ...simplonMonoBase,
    lineHeight: '1.15em',
    fontSize: '70px',
    [breakpointDown('sm')]: {
      fontSize: '48px',
    },
  },
  'h1r-mono': {
    ...simplonMonoBase,
    lineHeight: '1.15em',
    fontSize: '36px',
    [breakpointDown('sm')]: {
      fontSize: '28px',
    },
  },
  'h2r-mono': {
    ...simplonMonoBase,
    lineHeight: '1.15em',
    fontSize: '24px',
  },
  h1r: {
    ...saolBase,
    fontSize: '36px',
    [breakpointDown('sm')]: {
      fontSize: '28px',
    },
  },
  h2r: {
    ...saolBase,
    fontSize: '28px',
    [breakpointDown('sm')]: {
      fontSize: '20px',
    },
  },
  h1: {
    ...saolBase,
    fontSize: '36px',
    letterSpacing: 1,
  },
  h2: {
    ...saolBase,
    fontSize: '28px',
  },
  h3: {
    ...saolBase,
    fontSize: '20px',
  },
  h4: {
    ...saolBase,
    fontSize: '16px',
  },
  mono1: {
    ...simplonMonoBase,
    fontSize: '15px',
  },
  mono2: {
    ...simplonMonoBase,
    fontSize: '13px',
  },
  mono3: {
    ...simplonMonoBase,
    fontSize: '11px',
  },
  mono4: {
    ...simplonMonoBase,
    fontSize: '8px',
  },
  p1: {
    ...simplonNormBase,
    fontSize: '16px',
  },
  p2: {
    ...simplonNormBase,
    fontSize: '14px',
  },
  p3: {
    ...simplonNormBase,
    fontSize: '12px',
  },
  p4: {
    ...simplonNormBase,
    fontSize: '11px',
  },
};

// util classes
const utilsClasses = {
  resetButton: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
    padding: 0,
  },
};

// XXX: WIP. still need refinements
// var styles = getComputedStyle(document.documentElement);
// var value = String(styles.getPropertyValue('--primary-color')).trim();
const fullHeight = () => {
  return (
    typeof window !== 'undefined' &&
    `calc(${window.innerHeight}px - ${legacyVars.props.headerHeight})`
  );
};
// const fullHeightWithFixedCTA = () =>
//   typeof window !== 'undefined' && window.innerHeight - headerHeight - buttonHeight;

export const legacyTheme = {
  ...legacyVars,
  typography: {
    useNextVariants: true,
    ...legacyVars.typography,
    ...typographyVariants,
  },
  utils: utilsClasses,
  breakpoints: {
    values: breakpoints,
    /**
     * @deprecated Use up 99% of the time & down in niche cases
     */
    match: breakpointDown,
    down: breakpointDown,
    up: breakpointUp,
    ipadProPortrait: ipadProPortraitBreakpoints,
  },
  props: {
    fullHeight: fullHeight(),
    ...legacyVars.props,
  },
};
